import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  /*height: 100%;*/
  margin-bottom: -100px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }

  dados-pessoais-frame {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 60%;
    min-height: 100vh;
    padding-right: 25px;
    padding-bottom: 75px;
    padding-left: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid #67c7f1;
    border-left: 1px solid #67c7f1;
    @media screen and (max-width: 991px) {
      width: 85%;
    }
    @media screen and (max-width: 767px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  botao-inicio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
  }

  botao-inicio-icone {
    margin-right: 10px;
  }

  botao-inicio-label {
    width: 90px;
    color: #67c7f1;
    font-size: 12px;
  }

  label-alterar {
    width: 90px;
    color: #0c2d83;
    font-size: 10px;
    font-weight: 700;
    padding-left: 10px;
    cursor: pointer;

    &:hover {
      text-decoration-line: underline;
    }
  }

  dados-pessoais-linha-titulo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 55px;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #0c2d83;
    @media screen and (max-width: 767px) {
      height: 45px;
    }
  }

  dados-pessoais-titulo {
    padding-right: 25px;
    padding-left: 25px;
    color: #fff;
    font-size: 32px;
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }

  dados-pessoais-linha-subtitulo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 39px;
    margin-top: 25px;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #0c2d83;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      height: 30px;
    }
  }

  dados-pessoais-sub-titulo {
    padding-right: 25px;
    padding-left: 15px;
    color: #fff;
    font-size: 18px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  dados-pessoais-bloco {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: none none none solid;
    border-width: 1px 1px 1px 3px;
    border-color: #000 #000 #000 #0c2d83;
  }

  dados-pessoais-subtitulo {
    color: #0c2d83;
    font-size: 16px;
    font-weight: 700;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  dados-pessoais-subtitulo-white {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    @media screen and (max-width: 510px) {
      font-size: 14px;
    }
  }

  dados-pessoais-bloco-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 510px) {
      display: contents;
    }
  }

  dados-pessoais-bloco-parte02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 510px) {
      width: 100%;
    }
  }

  dados-pessoais-bloco-parte02-100 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 510px) {
      width: 100%;
    }
  }

  dados-pesssoais-item {
    margin-top: 5px;
    margin-bottom: 20px;
    padding-left: 5px;
    border-left: 1px solid #000;
  }
  dados-pesssoais-item-endereco {
    margin-top: 5px;
    margin-bottom: 20px;
    padding-left: 5px;
    border-left: 1px solid #000;
    width: 100%;
    padding-right: 25px;
  }

  dados-pesssoais-item-copy {
    width: 100%;
    margin-top: 5px;
    padding-top: 17px;
    padding-left: 5px;
  }

  dados-pessoais-bloco-cadastrar-endereco {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    margin-bottom: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: none none none solid;
    border-width: 1px 1px 1px 3px;
    border-color: #000 #000 #000 #0c2d83;
  }

  dados-pessoais-abrir-cadastro {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    height: 39px;
    margin-bottom: 10px;
    padding-left: 5px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #0c2d83;
    cursor: pointer;
    @media screen and (max-width: 510px) {
      width: 100%;
      height: 30px;
    }
  }

  cadastro-botao-frame {
    position: static;
    left: auto;
    top: 0%;
    right: -60px;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 39px;
    height: 39px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    cursor: pointer;
  }

  cadastro-botao {
    position: relative;
    display: flex;
    width: 30px;
    height: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
  }

  arrow-botao-frame {
    position: static;
    left: auto;
    top: 0%;
    right: -60px;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 39px;
    height: 39px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    cursor: pointer;
  }

  arrow-botao {
    position: relative;
    display: flex;
    width: 30px;
    height: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
  }

  cadastro-botao-barra-horizontal {
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #fff;
  }

  cadastro-botao-barra-vertical {
    position: absolute;
    width: 2px;
    height: 20px;
    background-color: #fff;
  }

  dados-pessoais-form {
    width: 100%;
    margin-bottom: 0px;
  }

  dados-pessoais-form-frame {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  dados-pessoais-bloco-colunas {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  dados-pessoais-item-coluna {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 22%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  dados-pessoais-item-coluna-small {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 9%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  dados-pessoais-item-coluna-big {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  dados-pessoais-coluna-labels {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    font-size: 11px;
    font-weight: 700;
    text-align: center;
  }
`;

export const BlocoLista = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  padding-left: 5px;
  border-left: 1px solid #000;
`;

export const Bloco = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: none none none solid;
  border-width: 1px 1px 1px 3px;
  border-color: #000 #000 #000 #0c2d83;

  table {
    width: 100%;
    border: 0;

    thead {
      tr {
        height: 60px;
      }
      th {
        font-weight: 700;
        font-size: 11px;
        align-items: center;
        font-family: 'Open Sans', sans-serif;
        @media screen and (max-width: 767px) {
          font-size: 9px;
        }
      }
      td {
        font-weight: 700;
        font-size: 11px;
        align-items: center;
        font-family: 'Open Sans', sans-serif;
      }
    }

    tbody {
      tr {
      }
      th {
        font-weight: 300;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
      }
      td {
        font-weight: 300;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        @media screen and (max-width: 767px) {
          font-size: 13px;
        }
      }
    }
  }

  ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 510px) {
      width: 100%;
    }

    li {
      margin-top: 5px;
      margin-bottom: 20px;
      padding-left: 5px;
      padding-top: 0;
      padding-bottom: 0;
      border-left: 1px solid #000;

      span {
        margin-bottom: 5px;
        color: #000;
        font-size: 11px;
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
        @media screen and (max-width: 767px) {
          font-size: 9px;
        }
      }

      p {
        margin-top: 5px;
        font-weight: 300;
        color: #000;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
`;

export const BlocoCadastro = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-bottom: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: none none none solid;
  border-width: 1px 1px 1px 3px;
  border-color: #000 #000 #000 #0c2d83;

  form {
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 510px) {
      display: contents;
    }
  }

  input {
    width: 100%;
    margin-bottom: 0px;
    display: block;
    height: 38px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #cccccc;
  }

  select {
    width: 100%;
    margin-bottom: 0px;
    display: block;
    height: 38px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #cccccc;
  }
`;

export const DadosPesssoaisItemLabel = styled.div`
  margin-bottom: 5px;
  color: #000;
  font-size: 11px;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 9px;
  }
`;

export const DadosPesssoaisItemInfo = styled.div`
  margin-top: 5px;
  font-weight: 300;
  color: #000;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

export const CorpoForm = styled.div`
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  @media screen and (max-width: 510px) {
    display: contents;
  }
`;

export const CampoInput = styled.div`
  width: 100%;
  margin-bottom: 0px;
  margin-right: 10px;
  display: block;
  height: 38px;

  label {
    padding-top: 5px;
    color: #0c2d83;
    display: block;
  }

  span {
    color: #f93a37;
    padding-top: 5px;
    padding-left: 15px;
    float: left;
  }
`;

export const BotaoCadastrar = styled.button`
  width: 150px;
  height: 39px;
  margin-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #818181;
  -webkit-transition: background-color 200ms
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: 600;
  text-align: center;
  display: inline-block;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    background-color: #0c2d83;
  }
`;

export const Div = styled.div`
  width: 100%;
`;

export const ModalAlert = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 350px;
    height: 180px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    align-items: center;
  }

  pop-up-sair-texto {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0c2d83;
    font-size: 16px;
  }

  pop-sair-frame-parte02 {
  }

  button {
    width: 100px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #0c2d83;
    }
  }
`;
export const ModalEstadoCiv = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 400px;
    height: 130px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: end;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  }

  pop-up-sair-texto {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0c2d83;
    font-size: 16px;
  }

  div-corpo {
    display: -webkit-inline-box;
  }

  select {
    width: 150px;
    border-radius: 0px;
    background-color: #fff;
    color: #000;
    display: block;
    min-width: 50%;
    height: 38px;
    padding: 8px 12px;
    margin-right: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.428571429;
    border: solid 1px #0c2d83;
    //align-self: center;
  }

  icone-sair {
    align-self: flex-end;
    cursor: pointer;
  }

  pop-sair-frame-parte02 {
  }

  button {
    width: 100px;
    height: 38px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #0c2d83;
    }
  }
`;

export const ModalPPE = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 400px;
    height: 130px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: end;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  }

  pop-up-sair-texto {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0c2d83;
    font-size: 16px;
  }

  div-corpo {
    display: -webkit-inline-box;
  }

  select {
    width: 150px;
    border-radius: 0px;
    background-color: #fff;
    color: #000;
    display: block;
    min-width: 50%;
    height: 38px;
    padding: 8px 12px;
    margin-right: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.428571429;
    border: solid 1px #0c2d83;
    //align-self: center;
  }

  icone-sair {
    align-self: flex-end;
    cursor: pointer;
  }

  pop-sair-frame-parte02 {
  }

  button {
    width: 100px;
    height: 38px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #0c2d83;
    }
  }
`;
