import styled from 'styled-components';

export const Container = styled.div`
  font-weight: 300;
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0px;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  botao-top-link {
    padding: 5px 8px;
    background-color: #67c7f1;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }
`;
