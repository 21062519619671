import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import Simulador from './simulador';
import Solicitacao from './solicitacao';
import api from '../../services/api';
import {
  SubmenusEmprestimo,
  BotaoSubmenuSimulador,
  BotaoSubmenuSolicitacao,
} from './styles';
import { store } from '../../store';

function Corpo() {
  const [visible, setVisible] = useState(1);

  const [state, setState] = useState({
    EmprestimoSimuladorGet: null,
    EmprestimoSolicitacao: null,
    EmprestimoRegulamento: null,
    DadosBancarios: null,
    ListaBancos: null,
    Erro: '',
  });

  function HandleSimulVisible() {
    setVisible(1);
  }

  function HandleSolicitVisible() {
    setVisible(2);
  }

  useEffect(() => {
    async function pegaEmprestimo() {
      try {
        const t = store.getState().auth.token;

        const parametros = Base64.encode(`{"token":"${t}"}`);
        const parametrosFinal = Base64.encode(parametros);

        const responseSimulGet = await api.get(
          `/wsEmprestimoSimulador.rule?sys=PRT&Entrada=${parametrosFinal}`
        );
        const responseSolicitGet = await api.get(
          `/wsEmprestimoSolicitacao.rule?sys=PRT&Entrada=${parametrosFinal}`
        );
        const responseRegulGet = await api.get(
          `/wsEmprestimoRegulamento.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        const responseDadosBancGet = await api.get(
          `/wsDadosBancarios.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        setState({
          EmprestimoSimuladorGet: responseSimulGet.data,
          EmprestimoSolicitacao: responseSolicitGet.data,
          EmprestimoRegulamento: responseRegulGet.data,
          DadosBancarios: responseDadosBancGet.data,
        });
      } catch (err) {
        setState({ Erro: err });
      }
    }
    pegaEmprestimo();
  }, []);

  if (
    state.EmprestimoSimuladorGet === undefined ||
    state.EmprestimoSimuladorGet === null
  ) {
    return (
      <SubmenusEmprestimo>
        <p>Desculpe, não foi possível carregar seus empréstimos.</p>
        <p>
          Caso você tenha feito sua adesão ao plano recentemente, suas
          informações ainda estão sendo processadas pelo sistema.
        </p>
        <p>
          Se tiver dúvidas entre em contato através do{' '}
          <a href="https://onosco" target="_blank" rel="noopener noreferrer">
            Fale Conosco.
          </a>
        </p>
      </SubmenusEmprestimo>
    );
  }

  const dadosEmprestimoGet = state.EmprestimoSimuladorGet;

  const { dadosSolicitacao } = state.EmprestimoSolicitacao;

  const { dadosRegulamento } = state.EmprestimoRegulamento;

  const { dadosBancarios } = state.DadosBancarios;

  const { listaBancos } = state.DadosBancarios;

  return (
    <SubmenusEmprestimo>
      <w-tab-menu>
        <BotaoSubmenuSimulador
          visible={visible}
          onClick={visible === 1 ? '' : HandleSimulVisible}
        >
          Simulador
        </BotaoSubmenuSimulador>
        <BotaoSubmenuSolicitacao
          visible={visible}
          onClick={visible === 2 ? '' : HandleSolicitVisible}
        >
          Solicitação
        </BotaoSubmenuSolicitacao>
      </w-tab-menu>
      <w-tab-content>
        <w-tab-pane>
          {visible === 2 ? (
            <Solicitacao dadosSolicit={dadosSolicitacao} />
          ) : (
            <Simulador
              dadosSimul={dadosEmprestimoGet}
              dadosRegul={dadosRegulamento}
              dadosBanc={dadosBancarios}
              listBancos={listaBancos}
            />
          )}
        </w-tab-pane>
      </w-tab-content>
    </SubmenusEmprestimo>
  );
}

export default Corpo;
