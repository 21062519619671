/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { FaSpinner } from 'react-icons/fa';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import Collapse from '@material-ui/core/Collapse';
import { useDispatch } from 'react-redux';
import { Container, Div } from './styles';
import BotaoTopo from '../../components/BotaoTopo';
import BlocoDadosPessoais from './components/01dadospessoais';
/* import BlocoFiliacao from './components/02filiacao';
import BlocoNacionalidade from './components/03nacionalidade';
import BlocoRG from './components/04rg';
import BlocoOcupacao from './components/05ocupacao';
import BlocoEscolaridade from './components/06escolaridade';
import BlocoAcessibilidade from './components/07acessibilidade'; */
import BlocoEnderecos from './components/08enderecos';
import CadastrarEndereco from './components/09cadastrarendereco';
import BlocoTelefones from './components/10telefones';
// import CadastrarTelefone from './components/11cadastrartelefone';
import BlocoEmails from './components/12emails';
// import CadastrarEmail from './components/13cadastraremail';
import BlocoDadosBancarios from './components/14dadosbancarios';
import BlocoDadosProfissionais from './components/15dadosprofissionais';
import BlocoAdesao from './components/16adesao';
import BlocoPerfilInvestimento from './components/17perfildeinvestimento';
import BlocoCusteioPlano from './components/18custeioplano';
import BlocoCusteioFaixa from './components/19custeiofaixa';

import BotaoInicio from '../../assets/icone-home-orange.svg';
import Footer from '../../components/Footer';
import { store } from '../../store';
import api from '../../services/api';
import { signOut } from '../../store/modules/auth/actions';

function DadosPessoais() {
  const [visibleGeral, setVisibleGeral] = useState(true);
  const [visibleContatos, setVisibleContatos] = useState(true);
  const [visibleBanco, setVisibleBanco] = useState(true);
  const [visibleOrg, setVisibleOrg] = useState(true);
  const [visiblePlano, setVisiblePlano] = useState(true);
  const [state, setState] = useState({
    DadosPessoais: {},
    Erro: '',
  });
  const [listaUF, setListaUF] = useState({
    Dados: {},
  });

  const dispatch = useDispatch();

  useEffect(() => {
    async function pegaDadosPessoais() {
      const t = store.getState().auth.token;

      const parametros = Base64.encode(`{"token":"${t}"}`);
      const parametrosFinal = Base64.encode(parametros);

      await api
        .get(`/wsPessoa.rule?sys=PRT&Entrada=${parametrosFinal}`)
        .then((res) =>
          setState({
            DadosPessoais: res.data,
          })
        )
        .catch((res) =>
          res.message === 'Request failed with status code 408'
            ? dispatch(signOut())
            : setState({
                DadosPessoais: {},
              })
        );

      const parametrosLista = Base64.encode(
        `{"token": "${t}", "pais": "Brasil", "estado": ""}`
      );
      const parametrosListaFinal = Base64.encode(parametrosLista);

      await api
        .get(
          `/wsPaisEstadoMunicipio.rule?sys=PRT&Entrada=${parametrosListaFinal}`
        )
        .then((res) => setListaUF({ Dados: res.data }))
        .catch(() => setListaUF({ Dados: {} }));
    }
    pegaDadosPessoais();
  }, []);

  if (state.DadosPessoais === undefined) {
    return (
      <>
        <FaSpinner color="#67C7F1" size={20} />
      </>
    );
  }

  function HandleToggleVisibleGeral() {
    setVisibleGeral(!visibleGeral);
  }

  function HandleToggleVisibleContatos() {
    setVisibleContatos(!visibleContatos);
  }

  function HandleToggleVisibleBanco() {
    setVisibleBanco(!visibleBanco);
  }

  function HandleToggleVisibleOrg() {
    setVisibleOrg(!visibleOrg);
  }

  function HandleToggleVisiblePlano() {
    setVisiblePlano(!visiblePlano);
  }

  const { dadosPessoais } = state.DadosPessoais;
  const { dadosEndereco } = state.DadosPessoais;
  const { dadosTelefone } = state.DadosPessoais;
  const { dadosBancarios } = state.DadosPessoais;
  const { dadosOrganizacao } = state.DadosPessoais;
  const { dadosPlano } = state.DadosPessoais;

  const dadosEstados = listaUF.Dados.dados;

  return (
    <>
      <Container>
        <dados-pessoais-frame>
          <Link to="/main">
            <botao-inicio>
              <botao-inicio-icone>
                <img src={BotaoInicio} alt="BotaoInicio" width="30" />
              </botao-inicio-icone>
              <botao-inicio-label>Voltar à página inicial</botao-inicio-label>
            </botao-inicio>
          </Link>
          <dados-pessoais-linha-titulo>
            <dados-pessoais-titulo>Dados pessoais</dados-pessoais-titulo>
          </dados-pessoais-linha-titulo>

          <dados-pessoais-linha-subtitulo onClick={HandleToggleVisibleGeral}>
            <dados-pessoais-sub-titulo>Geral</dados-pessoais-sub-titulo>
            <arrow-botao-frame>
              <arrow-botao>
                {visibleGeral ? (
                  <BsChevronUp size={20} />
                ) : (
                  <BsChevronDown size={20} />
                )}
              </arrow-botao>
            </arrow-botao-frame>
          </dados-pessoais-linha-subtitulo>
          <Div>
            <Collapse in={visibleGeral}>
              {BlocoDadosPessoais(dadosPessoais, 0)}
              {/* {BlocoFiliacao(dadosPessoais, 0)}
              {BlocoNacionalidade(dadosPessoais, 0)}
              {BlocoRG(dadosPessoais, 0)}
              {BlocoOcupacao(dadosPessoais, 0)}
              {BlocoEscolaridade(dadosPessoais, 0)}
              {BlocoAcessibilidade(dadosPessoais, 0)} */}
            </Collapse>
          </Div>

          <dados-pessoais-linha-subtitulo onClick={HandleToggleVisibleContatos}>
            <dados-pessoais-sub-titulo>
              Informações de contato
            </dados-pessoais-sub-titulo>
            <arrow-botao-frame>
              <arrow-botao>
                {visibleContatos ? (
                  <BsChevronUp size={20} />
                ) : (
                  <BsChevronDown size={20} />
                )}
              </arrow-botao>
            </arrow-botao-frame>
          </dados-pessoais-linha-subtitulo>

          <Div>
            <Collapse in={visibleContatos}>
              {BlocoEnderecos(dadosEndereco, 0)}
              {CadastrarEndereco(dadosEstados)}
              {BlocoTelefones(dadosTelefone)}
              {/* <CadastrarTelefone /> */}
              {BlocoEmails(dadosPessoais, 0)}
              {/* <CadastrarEmail /> */}
            </Collapse>
          </Div>

          <dados-pessoais-linha-subtitulo onClick={HandleToggleVisibleBanco}>
            <dados-pessoais-sub-titulo>
              Dados bancários
            </dados-pessoais-sub-titulo>
            <arrow-botao-frame>
              <arrow-botao>
                {visibleBanco ? (
                  <BsChevronUp size={20} />
                ) : (
                  <BsChevronDown size={20} />
                )}
              </arrow-botao>
            </arrow-botao-frame>
          </dados-pessoais-linha-subtitulo>
          <Div>
            <Collapse in={visibleBanco}>
              {BlocoDadosBancarios(dadosBancarios, 0)}
            </Collapse>
          </Div>

          <dados-pessoais-linha-subtitulo onClick={HandleToggleVisibleOrg}>
            <dados-pessoais-sub-titulo>
              Dados profissionais
            </dados-pessoais-sub-titulo>
            <arrow-botao-frame>
              <arrow-botao>
                {visibleOrg ? (
                  <BsChevronUp size={20} />
                ) : (
                  <BsChevronDown size={20} />
                )}
              </arrow-botao>
            </arrow-botao-frame>
          </dados-pessoais-linha-subtitulo>
          <Div>
            <Collapse in={visibleOrg}>
              {BlocoDadosProfissionais(dadosOrganizacao, 0)}
            </Collapse>
          </Div>

          <dados-pessoais-linha-subtitulo onClick={HandleToggleVisiblePlano}>
            <dados-pessoais-sub-titulo>Participação</dados-pessoais-sub-titulo>
            <arrow-botao-frame>
              <arrow-botao>
                {visiblePlano ? (
                  <BsChevronUp size={20} />
                ) : (
                  <BsChevronDown size={20} />
                )}
              </arrow-botao>
            </arrow-botao-frame>
          </dados-pessoais-linha-subtitulo>
          <Div>
            <Collapse in={visiblePlano}>
              {BlocoAdesao(dadosPlano, 0)}
              {BlocoPerfilInvestimento(dadosPlano, 0)}
              {BlocoCusteioPlano(dadosPlano, 0)}
              {/* {BlocoCusteioFaixa(dadosPlano, 0)} */}
            </Collapse>
          </Div>
        </dados-pessoais-frame>
      </Container>
      <BotaoTopo />
      <Footer />
    </>
  );
}

export default DadosPessoais;
