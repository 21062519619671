import styled from 'styled-components';
import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  font-weight: 300;
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  margin-top: 25px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  @media screen and (max-width: 991px) {
    width: 90%;
  }

  menu-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 65px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    background-color: #0c2d83;
  }

  menu-header-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 75%;
    height: 65px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  menu-header-parte02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 25%;
    height: 65px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  menu-icone {
    width: 65px;
    height: 65px;
    margin-right: 25px;
    margin-left: 25px;
    padding: 10px;
    vertical-align: center;
    align-items: center;

    @media screen and (max-width: 480px) {
      width: 52px;
      height: 52px;
    }
  }

  menu-titulo {
    color: #fff;
    font-size: 28px;

    @media screen and (max-width: 480px) {
      font-size: 22px;
    }
  }

  menu-botao {
    position: static;
    left: auto;
    top: 0%;
    right: -60px;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 65px;
    height: 65px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    cursor: pointer;
  }

  botao-p {
    position: relative;
    display: flex;
    width: 40px;
    height: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
  }
`;
export const CorpoSimulador = styled.div`
  /*display: ${(props) => (props.visible ? 'block' : 'none')};*/

  overflow: hidden;
  width: 100%;
  /*height: 425px;*/
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  /* background-color: #c8c8c8; */
`;

export const SubmenusSimulador = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 25px;

  a {
    color: #0c2d83;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  w-tab-menu {
    position: relative;
    display: flex;
  }

  w-tab-content {
    position: relative;
    display: block;
    overflow: hidden;
  }

  w-tab-pane {
    position: relative;
    display: block;
    /*display: block*/
  }

  submenu-bloco {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: none none none solid;
    border-width: 1px 1px 1px 3px;
    border-color: #000 #000 #000 #0c2d83;
  }

  submenu-bloco-horizontal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: none none none solid;
    border-width: 1px 1px 1px 3px;
    border-color: #000 #000 #000 #0c2d83;
    height: auto;
    > div {
      display: flex;
      width: 100%;
      @media screen and (max-width: 510px) {
        display: table-column;
      }
    }
  }

  submenu-bloco-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: solid;
    border-width: 3px;
    border-color: #0c2d83;
  }
  submenu-bloco-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 85px;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      display: contents;
    }
  }

  slider-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 80%;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      width: 96%;
    }
  }

  slider-parte02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
      margin-left: 12px;
      width: 40%;
    }
  }

  submenu-titulos {
    margin-bottom: 10px;
    color: #0c2d83;
    font-size: 16px;
    font-weight: 700;
  }

  submenu-item-conteudo-ajustado {
    margin-top: 5px;
    margin-bottom: 0px;
    padding-left: 5px;
    border-left: 1px solid #000;
  }
`;
export const SubmenuObservacoes = styled.div`
  margin-top: 5px;
  color: #000;
  font-size: 14px;
`;

export const BotaoSubmenuResgate = styled.button`
  height: 65px;
  color: #fff;
  border: 1px solid #fff;
  border-style: inset;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  font-size: 16px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;

  background-color: ${(props) => (props.visible === 1 ? '#0C2D83' : '#777')};

  @media screen and (max-width: 767px) {
    height: 55px;
    font-size: 14px;
    padding: 8px;
  }
`;

export const BotaoSubmenuAposentadoria = styled.button`
  height: 65px;
  /*background-color: #777;*/
  color: #fff;
  border: 1px solid #fff;
  border-style: inset;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  font-size: 16px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;

  background-color: ${(props) => (props.visible === 2 ? '#0C2D83' : '#777')};

  @media screen and (max-width: 767px) {
    height: 55px;
    font-size: 14px;
    padding: 8px;
  }
`;

export const SubmenuCorpoFrame = styled.div`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid #000;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 0px none #0c2d83;
  border-top-style: none;

  frame-horizontal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  submenu-bloco-coluna-simulador {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: auto;
    margin-right: 10px;
    margin-left: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  submenu-bloco-coluna-simulador-01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //width: auto
    width: 20%;
    height: auto;
    margin-right: 10px;
    margin-left: 4px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    @media screen and (max-width: 510px) {
      width: 40%;
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  submenu-bloco-coluna-simulador-02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: auto;
    margin-right: 10px;
    margin-left: 4px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 510px) {
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  submenu-bloco-coluna-simulador-03 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 25%;
    height: auto;
    margin-right: 10px;
    margin-left: 4px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 510px) {
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  submenu-bloco-coluna-simulador-04 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 25%;
    height: auto;
    margin-right: 10px;
    margin-left: 4px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 510px) {
      margin-left: 2px;
      margin-right: 2px;
    }
  }

  submenu-bloco-coluna-simulador-05 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 250px;
    height: auto;
    margin-right: 10px;
    margin-left: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 991px) {
      width: 190px;
    }
  }

  submenu-bloco-coluna-simulador-06 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //width: 30%;
    height: auto;
    margin-right: 10px;
    margin-left: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 125px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 510px) {
      width: 80%;
      align-items: flex-start;
      margin-left: 5px;
      > input {
        width: 150px;
      }
    }
  }

  submenu-bloco-coluna-simulador-07 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //width: 30%;
    width: 20%;
    height: auto;
    margin-right: 10px;
    margin-left: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 991px) {
      width: 24%;
    }
    @media screen and (max-width: 510px) {
      width: 80%;
      align-items: flex-start;
    }
  }

  submenu-bloco-coluna-simulador-08 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //width: 30%;
    width: 20%;
    height: auto;
    margin-right: 10px;
    margin-left: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 8px;
    @media screen and (max-width: 991px) {
      width: 24%;
    }
    @media screen and (max-width: 510px) {
      width: 80%;
      align-items: flex-start;
    }
  }

  submenu-item-coluna-aposent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    margin-right: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  submenu-item-coluna-aposent-01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    margin-right: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  submenu-item-coluna-aposent-02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 65%;
    margin-right: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  submenu-item-coluna-aposent-03 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 25%;
    margin-right: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  submenu-bloco-coluna-simulador-aposent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: auto;
    margin-right: 10px;
    margin-left: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  simulador-form-frame-aposent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin: 0 0 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  submenu-coluna-labels-simulador {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    font-size: 11px;
    font-weight: 700;
    text-align: right;
    /* @media screen and (max-width: 510px) {
      background: red;
    } */
  }

  submenu-coluna-info-aposent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    font-size: 16px;
    @media screen and (max-width: 510px) {
      font-size: 12px;
    }
  }

  submenu-coluna-info-aposent-bold {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    @media screen and (max-width: 510px) {
      font-size: 14px;
    }
  }

  submenu-coluna-info-aposent-result {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    margin-top: 6px;
    margin-bottom: 6px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    font-size: 16px;
    @media screen and (max-width: 510px) {
      font-size: 12px;
    }
  }

  input {
    width: 100%;
    height: 40px;
    margin-top: 4px;
    margin-right: 0px;
    margin-bottom: 10px;
    display: block;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    vertical-align: middle;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    text-align: end;
    min-width: 100px;
    @media screen and (max-width: 510px) {
      padding: 8px 4px;
      font-size: 12px;
      min-width: 58px;
    }
  }

  select {
    width: 100%;
    height: 40px;
    min-width: 120px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 0px;
    background-color: #0c2d83;
    color: #fff;
    border: none;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    @media screen and (max-width: 510px) {
      width: 150px;
    }
  }

  button {
    display: flex;
    width: 150px;
    height: 40px;
    margin-top: 4px;
    margin-right: 0px;
    margin-bottom: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;

    &:hover {
      background-color: #0c2d83;
    }

    &:disabled {
      background-color: #818181;
      cursor: auto;
    }
  }

  submenu-bloco-aposent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  submenu-item-conteudo-aposent {
    width: 50%;
    margin-top: 5px;
    margin-right: 24px;
    margin-bottom: 20px;
    padding-left: 5px;
    border-left: 1px solid #000;
  }

  frame-horizontal-aposent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
`;

export const Divisoria = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #000;
`;

export const SubmenuLabels = styled.div`
  margin-bottom: 5px;
  color: #000;
  font-size: 11px;
  font-weight: 700;
`;

export const SubmenuInfos = styled.div`
  margin-top: 5px;
  color: #000;
  font-size: 16px;
`;

export const ModalAlert = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 350px;
    height: 180px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    align-items: center;
  }

  pop-up-sair-texto {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0c2d83;
    font-size: 16px;
  }

  pop-sair-frame-parte02 {
  }

  button {
    width: 100px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #0c2d83;
    }
  }
`;

export const SliderContribuicao = withStyles({
  root: {
    color: '#777',
    height: 8,
    '&$disabled': {
      color: '#BDBDBD',
    },
  },
  disabled: {},
  thumb: {
    height: 20,
    width: 20,
    color: '#0C2D83',
    backgroundColor: '#0C2D83',
    border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '&$disabled': {
      height: 20,
      width: 20,
      color: '#BDBDBD',
      backgroundColor: '#BDBDBD',
      border: '2px solid currentColor',
      marginTop: -6,
      marginLeft: -10,
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50%)',
    top: -16,
    '& *': {
      background: 'transparent',
      color: '#000',
      fontFamily: ['Open Sans', 'sans-serif'],
    },
  },
  track: {
    height: 8,
    borderRadius: 0,
    '&$disabled': {
      color: '#BDBDBD',
    },
  },
  rail: {
    height: 8,
    borderRadius: 2,
    '&$disabled': {
      color: '#BDBDBD',
    },
  },
  mark: {
    backgroundColor: '#777',
    height: 20,
    width: 2,
    marginTop: 20,
    '&$disabled': {
      backgroundColor: '#BDBDBD',
    },
  },
  markLabel: {
    marginTop: 30,
    fontSize: 12,
    fontFamily: ['Open Sans', 'sans-serif'],
  },
})(Slider);
