/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'; //
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
// import { Cpf } from '../../services/masks';
import FooterAuth from '../../components/FooterAuth';

import { signInRequest } from '../../store/modules/auth/actions';

import IconeOlho from '../../assets/icone-mostrar-senha.svg';
import IconeOlhoFechado from '../../assets/icone-ocultar-senha.svg';
import {
  Container,
  // WCheckbox,
  LoginCampo,
  LoginTitulo,
  LoginCorpo,
  DivBotao,
  LoginCampo2,
} from './styles';

const schema = Yup.object().shape({
  idCliente: Yup.string().required('Campo obrigatório'),
  cpfUsuario: Yup.string().required('Campo obrigatório'),
  senhaUsuario: Yup.string().required('Campo obrigatório'),
  codSistema: Yup.string().required('Campo obrigatório'),
});

function Login() {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  function handleSubmit({ idCliente, cpfUsuario, senhaUsuario }) {
    dispatch(signInRequest(idCliente, cpfUsuario, senhaUsuario));
  }

  function HandleToggleVisible() {
    setVisible(!visible);
  }

  return (
    <>
      <Container>
        <login-frame>
          <login-corpo00>
            <login-corpo-parte00>
              <LoginTitulo>Bem-vindo(a) à sua área na ASPPrev!</LoginTitulo>
            </login-corpo-parte00>
          </login-corpo00>

          <LoginCorpo>
            <Form schema={schema} onSubmit={handleSubmit}>
              <LoginCampo>
                <login-input>
                  <Input name="cpfUsuario" maxLength="14" />
                  <img alt="" />
                </login-input>
                <label>CPF</label>
              </LoginCampo>
              <LoginCampo>
                <login-input>
                  <Input
                    name="senhaUsuario"
                    type={visible ? 'text' : 'password'}
                  />
                  <img
                    onClick={HandleToggleVisible}
                    src={visible ? IconeOlhoFechado : IconeOlho}
                    alt="IconeOlho"
                  />
                </login-input>
                <label>Senha</label>
              </LoginCampo>
              <LoginCampo2>
                <Input name="idCliente" value="1002" />
                {/* * 2132 * 1002 * 3132 * */}
                <Input name="codSistema" value="PRT" />
              </LoginCampo2>
              {/* <WCheckbox>
                <input name="checkbox" type="checkbox" />
                <span>Lembrar CPF e senha</span>
              </WCheckbox> */}
              <DivBotao>
                <button type="submit">
                  {loading ? 'Carregando...' : 'Entrar'}
                </button>
                <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
              </DivBotao>
            </Form>
          </LoginCorpo>
        </login-frame>
      </Container>
      <footer>
        <FooterAuth />
      </footer>
    </>
  );
}
export default Login;
