import styled from 'styled-components';

export const Container = styled.div`
  font-weight: 300;
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  margin-top: 25px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  @media screen and (max-width: 991px) {
    width: 90%;
  }

  menu-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 65px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    background-color: #0c2d83;
  }

  menu-header-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 75%;
    height: 65px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  menu-header-parte02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 25%;
    height: 65px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  menu-icone {
    width: 65px;
    height: 65px;
    margin-right: 25px;
    margin-left: 25px;
    padding: 10px;
    vertical-align: center;
    align-items: center;

    @media screen and (max-width: 480px) {
      width: 52px;
      height: 52px;
    }
  }

  menu-titulo {
    color: #fff;
    font-size: 28px;

    @media screen and (max-width: 480px) {
      font-size: 22px;
    }
  }

  menu-botao {
    position: static;
    left: auto;
    top: 0%;
    right: -60px;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 65px;
    height: 65px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    cursor: pointer;
  }

  botao-p {
    position: relative;
    display: flex;
    width: 40px;
    height: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
  }
`;
export const CorpoRelDocs = styled.div`
  overflow: hidden;
  width: 100%;
  /*height: 425px;*/
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  /* background-color: #c8c8c8; */
`;
export const SubmenusRelDocs = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 25px;

  w-tab-menu {
    position: relative;
    display: flex;
  }

  w-tab-content {
    position: relative;
    display: block;
    overflow: hidden;
  }

  w-tab-pane {
    position: relative;
    display: block;
  }

  submenu-bloco {
    display: grid;
    width: 100%;
    margin-bottom: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 50px;
    grid-template-columns: repeat(auto-fit, 220px);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: none none none solid;
    border-width: 1px 1px 1px 3px;
    border-color: #000 #000 #000 #0c2d83;
  }

  relatorios-e-documentos-item-frame {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 150px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    cursor: pointer;
  }
  relatorios-e-documentos-item-divisoria {
    width: 50px;
    height: 2px;
    margin-left: 5px;
    background-color: #0c2d83;
    align-self: baseline;
  }

  relatorios-e-documentos-item-titulo {
    margin-left: 5px;
  }
`;
export const SubmenuCorpoFrame = styled.div`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid #000;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 0px none #0c2d83;
  border-top-style: none;
`;
