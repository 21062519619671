import styled from 'styled-components';

export const PainelCorpo = styled.div`
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 16%;
  min-width: 180px;
  margin-right: 40px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
  @media screen and (max-width: 991px) {
    width: 25%;
    padding-left: 15px;
  }
  @media screen and (max-width: 767px) {
    width: 30%;
  }
  @media screen and (max-width: 510px) {
    width: 20%;
    min-width: 90px;
  }

  painel-corpo-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      height: 80px;
    }

    header-painel-frame {
      position: absolute;
      z-index: 2;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 510px) {
        display: inline-grid;
        justify-items: center;
      }
    }

    painel-p {
      display: flex;
      height: 55px;
      padding: 10px;
      align-items: center;
      @media screen and (max-width: 991px) {
        height: 45px;
      }
      @media screen and (max-width: 510px) {
        padding: 2px;
        height: 25px;
      }
    }

    painel-icone {
      /* width: 55px;
      height: 55px;
      //padding: 10px;-webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      border-style: solid;
      border-width: 1px;
      border-color: #67C7F1;
      border-radius: 50px; */
      img {
        width: 55px;
        height: 55px;
        border: solid #67c7f1;
        border-width: 4px;
        @media screen and (max-width: 991px) {
          width: 45px;
          height: 45px;
          border-width: 3px;
        }
        @media screen and (max-width: 510px) {
          width: 25px;
          height: 25px;
          border-width: 2px;
        }
      }
      /* @media screen and (max-width: 510px) {
        width: 20px;
        height: 20px;
      } */
    }

    /*width: 100px;
    height: 100px;
    margin-top: 15px;
    width: 80px;
    height: 80px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: #67C7F1;
    border-radius: 50px;
    cursor: pointer;*/

    img {
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
      border-radius: 50%;
      border: solid 2px #fff;
      width: 53px;
      height: 53px;
    }

    painel-titulo {
      margin-right: 5px;
      margin-left: 10px;
      color: #0c2d83;
      @media screen and (max-width: 991px) {
        font-size: 12px;
      }
    }

    painel-seta {
      display: block;
      margin-left: 0px;
      color: #0c2d83;
      font-size: 16px;
    }
  }
`;

export const PainelCorpoLista = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  @media screen and (max-width: 510px) {
    align-self: flex-end;
  }

  painel-corpo-parte02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    /* height: 256px; */
    /* height: 200px; */
    height: ${(props) => (props.size === 'normal' ? '200px' : '256px')};
    padding-right: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
`;

export const Divisoria = styled.div`
  width: 90%;
  min-width: 160px;
  height: 1px;
  background-color: #67c7f1;
  align-self: center;
`;

export const PainelCorpoAvatar = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};

  painel-corpo-parte02-avatar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 200px;
    padding-right: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;

    @media screen and (max-width: 991px) {
      padding-right: 10px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    @media screen and (max-width: 767px) {
      height: 80%;
    }
  }

  painel-foto {
    width: 100px;
    height: 100px;
    margin-top: 15px;
    width: 80px;
    height: 80px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-style: solid;
    border-width: 1px;
    border-color: #67c7f1;
    border-radius: 50px;
    cursor: pointer;

    @media screen and (max-width: 991px) {
      width: 70px;
      height: 70px;
    }
  }

  label {
    cursor: pointer;

    img {
      max-width: 100%;
      vertical-align: middle;
      display: inline-block;
      border-radius: 50%;
      border: solid 5px #fff;
      width: 78px;
      height: 78px;
    }

    input {
      display: none;
    }
  }

  foto-item {
    margin-top: 20px;
    padding: 0px;
    background-color: transparent;
    color: #0c2d83;
    text-decoration: none;
    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
  }

  alterar-foto-frame-botoes {
    margin: 10px;
    padding-top: 15px;
    cursor: pointer;
    @media screen and (max-width: 991px) {
      width: 90%;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
  }

  foto-botao {
    width: 67px;
    margin-top: 20px;
    margin-right: 10px;
    padding: 5px;
    background-color: #67c7f1;
    color: #fff;
    text-align: center;
    text-decoration: none;
    @media screen and (max-width: 991px) {
      width: 60px;
      font-size: 12px;
    }
  }

  foto-botao-cancelar {
    width: 67px;
    margin-top: 20px;
    margin-right: 10px;
    padding: 5px;
    background-color: #c8c8c8;
    color: #fff;
    text-align: center;
    text-decoration: none;
    @media screen and (max-width: 991px) {
      width: 60px;
      font-size: 12px;
    }
  }
`;

export const PainelItem = styled.ul`
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0px;
  background-color: transparent;
  color: #0c2d83;
  text-decoration: none;
  @media screen and (max-width: 991px) {
    font-size: 12px;
  }

  label {
    cursor: pointer;
  }
`;

export const PainelCorpoSombra = styled.div`
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 1;
  width: 16%;
  min-width: 180px;
  /* height: 356px; */
  /* height: 300px; */
  height: ${(props) => (props.size === 'normal' ? '300px' : '356px')};
  margin-right: 40px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.25);
  display: ${(props) => (props.visible ? 'block' : 'none')};

  @media screen and (max-width: 991px) {
    width: 25%;
    padding-left: 15px;
  }
  @media screen and (max-width: 767px) {
    width: 30%;
    height: 240px;
  }
  @media screen and (max-width: 510px) {
    background-color: #fff;
    height: 280px;
  }
`;

export const ModalConfirmacao = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.sair ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 400px;
    height: 250px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  }

  icone-sair {
    width: 50px;
  }

  pop-up-sair-titulo {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #67c7f1;
    font-size: 24px;
  }

  pop-sair-frame-parte02 {
  }

  button {
    width: 100px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #67c7f1;
    }
  }
`;

export const ModalPesquisa = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.pesquisa ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    height: 60%;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  }

  icone-sair {
    align-self: flex-end;
    cursor: pointer;
  }

  pop-up-sair-titulo {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #67c7f1;
    font-size: 24px;
  }

  pop-sair-frame-parte02 {
  }

  button {
    width: 100px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #67c7f1;
    }
  }
`;
