import React, { useState } from 'react';

import { Base64 } from 'js-base64';
import {
  SubmenuCorpoFrame,
  SubmenuLabels,
  SubmenuBotaoAjustado,
  ModalAlert,
  SubmenuObservacoes,
} from './styles';
import api from '../../services/api';
import { store } from '../../store';

function InfRendimentos(dadosInf) {
  const [datasInf] = useState(dadosInf.dadosInf);
  const [anoInf, setAnoInf] = useState('');
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  // const { dadosPlano } = store.getState().visaoGeral.profile;

  const { token } = store.getState().auth;

  let dtsList = '';
  if (datasInf === undefined || datasInf === '[]') {
    dtsList = <option value=""> </option>;
  } else {
    dtsList = datasInf.map((n) => (
      <option value={n.anoCalendario}>{`${n.anoCalendario}`}</option>
    ));
  }

  async function handleGerarInforme() {
    if (anoInf !== '') {
      try {
        const parametros = Base64.encode(`{
          "token": "${token}",
          "anoCalendario": "${anoInf}"
        }`);

        const parametrosFinal = Base64.encode(parametros);

        const response = await api.post(
          `/wsInformeRendimento.rule?sys=PRT&Entrada=${parametrosFinal}`
        );
        window.open(response.data.link);
      } catch (err) {
        setMsg(
          'Não foi possível gerar o informe de rendimentos agora. Tente novamente mais tarde.'
        );
        setAlerta(true);
      }
    } else if (anoInf === '' || anoInf === null) {
      setMsg('Selecione o ano no campo ao lado');
      setAlerta(true);
    }
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
  }

  return (
    <SubmenuCorpoFrame>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <submenu-bloco>
        <submenu-bloco-parte01>
          <div>
            <SubmenuLabels>Ano</SubmenuLabels>
            <select nome="anoInf" onChange={(e) => setAnoInf(e.target.value)}>
              <option value="">Escolha um...</option>
              {dtsList}
            </select>
          </div>
          {/* <div>
            <SubmenuLabels>Patrocinadora</SubmenuLabels>
            <select nome="tipo">
              <option value="">Escolha um...</option>
            </select>
          </div> */}
          <SubmenuBotaoAjustado onClick={handleGerarInforme}>
            Gerar informe
          </SubmenuBotaoAjustado>
        </submenu-bloco-parte01>
      </submenu-bloco>
      <submenu-bloco>
        <submenu-titulos>Observações</submenu-titulos>
        <SubmenuObservacoes>
          O informe de rendimentos está disponível para quem recebeu algum tipo
          de pagamento ou que efetuou contribuições extraordinárias. No caso de
          contribuições descontadas no salário, verifique o informe
          disponibilizado pela ASPPrev.
        </SubmenuObservacoes>
      </submenu-bloco>
    </SubmenuCorpoFrame>
  );
}

export default InfRendimentos;
