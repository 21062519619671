/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { Base64 } from 'js-base64';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import {
  Container,
  SenhaTitulo,
  SenhaSubTitulo,
  RecuperaSenhaCorpo,
  RecuperaSenhaCampo,
  DivBotao,
  RecuperaSenhaCampo2,
  ModalAlert,
} from './styles';
import FooterAuth from '../../components/FooterAuth';
import history from '../../services/history';

const schema = Yup.object().shape({
  idCliente: Yup.string().required('Campo obrigatório'),
  cpfUsuario: Yup.string().required('Campo obrigatório'),
  nascimento: Yup.string().required('Campo obrigatório'),
  codSistema: Yup.string().required('Campo obrigatório'),
});

function RecuperarSenha() {
  const [tipo, setTipo] = useState('S');
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [flag, setFlag] = useState(false);

  async function handleSubmit({ idCliente, cpfUsuario, nascimento }) {
    const parametros = Base64.encode(
      `{"cliente":"${idCliente}", "cpf":"${cpfUsuario}", "nascimento":"${nascimento}", "sistema":"PRT"}`
    );
    const parametrosFinal = Base64.encode(parametros);
    // const response =
    await api
      .post(`/wsAutenticacao.rule?sys=PRT&Entrada=${parametrosFinal}`)
      .then((res) => {
        const { token } = res.data;
        if (token !== null || token !== undefined || token !== '') {
          const p = Base64.encode(
            `{"token":"${token}", "modoRecuperacao":"${tipo}", "nascimento":"${nascimento}"}`
          );
          const pFinal = Base64.encode(p);
          // const res =
          api
            .post(`/wsSenha.rule?sys=PRT&Entrada=${pFinal}`)
            .then((rr) => {
              setMsg(rr.data.mensagem);
              setAlerta(true);
              setFlag(true);
            })
            .catch(() => {
              setMsg(
                'Algo deu errado. Tente novamente dentro de alguns minutos.'
              );
              setAlerta(true);
            });
        }
      })
      .catch(() => {
        setMsg(
          'Algo deu errado. Verifique se os dados informados estão corretos'
        );
        setAlerta(true);
      });
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
    if (flag === true) {
      history.push('/');
    }
  }

  return (
    <>
      <Container>
        <ModalAlert visible={alerta}>
          <popup-inner>
            <pop-up-sair-texto>{msg}</pop-up-sair-texto>
            <div>
              <button type="button" onClick={HandleToggleAlerta}>
                OK
              </button>
            </div>
          </popup-inner>
        </ModalAlert>
        <recupera-senha-frame>
          <recupera-senha-corpo00>
            <recupera-senha-corpo-parte00>
              <SenhaTitulo>Esqueceu sua senha?</SenhaTitulo>
              <SenhaSubTitulo>
                Digite o seu CPF e data de nascimento nos campos abaixo para
                recuperá-la
              </SenhaSubTitulo>
            </recupera-senha-corpo-parte00>
          </recupera-senha-corpo00>
          <RecuperaSenhaCorpo>
            <Form schema={schema} onSubmit={handleSubmit}>
              <RecuperaSenhaCampo>
                <recupera-senha-input>
                  <Input name="cpfUsuario" />
                </recupera-senha-input>
                <label>CPF</label>
              </RecuperaSenhaCampo>
              <RecuperaSenhaCampo>
                <recupera-senha-input>
                  <Input name="nascimento" type="date" />
                </recupera-senha-input>
                <label>Data de nascimento</label>
              </RecuperaSenhaCampo>
              <RecuperaSenhaCampo2>
                <Input name="idCliente" value="1002" />
                {/* * 2132 * 1002 * 3132 * */}
                <Input name="codSistema" value="HMG" />
              </RecuperaSenhaCampo2>

              <Input
                type="radio"
                name="tipo"
                value="S"
                checked={tipo === 'S'}
                onChange={(e) => setTipo(e.target.value)}
              />
              <label>SMS</label>
              <Input
                type="radio"
                name="tipo"
                value="E"
                checked={tipo === 'E'}
                onChange={(e) => setTipo(e.target.value)}
              />
              <label>E-mail</label>

              <DivBotao>
                <button type="submit">Recuperar</button>
                <Link to="/">Voltar</Link>
              </DivBotao>
            </Form>
          </RecuperaSenhaCorpo>
        </recupera-senha-frame>
      </Container>
      <footer>
        <FooterAuth />
      </footer>
    </>
  );
}

export default RecuperarSenha;
