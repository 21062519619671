import styled from 'styled-components';

export const Container = styled.div`
  font-weight: 300;
  position: fixed;
  left: auto;
  top: 40%;
  right: 0%;
  bottom: auto;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 35px;
  height: 200px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  transition: width 0.3s;
  background-color: #67c7f1;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  cursor: pointer;

  &:hover {
    width: 215px;
    transition: width 0.3s;
  }

  painel-fale-com-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid #fff;
    text-align: center;
  }
  text-block-6 {
    width: 125px;
    height: 35px;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    color: #fff;
    font-size: 16px;
    line-height: 2;
  }
`;
export const FaleConoscoCorpo = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 225px;
  height: 100%;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;

  icone-e-mail {
    width: 25px;
    height: 25px;
    margin-right: 0px;
    margin-left: 0px;
    /*background-image: url('../../assets/gericke-icone-e-mail-white.svg');*/
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  fale-conosco-tel {
    color: #fff;
    font-size: 14px;
  }

  painel-fale-com-espacador {
    width: 100%;
    height: 10px;
  }

  icone-telefone {
    width: 25px;
    height: 25px;
    margin: 5px 0px;
    /*background-image: url('../images/gericke-icone-telefone-white.svg');*/
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  fale-conosco-tel-legenda {
    margin-bottom: 5px;
    color: #fff;
    font-size: 10px;
  }
`;
